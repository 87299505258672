<mat-toolbar>
  <button
    mat-icon-button
    *ngIf="isResponsiveLayout | async"
    class="menu"
    (click)="toggleMenu()"
  >
    <mat-icon aria-hidden="false" aria-label="Menu">{{
      navOpened ? 'close' : 'menu'
    }}</mat-icon>
  </button>

  <span class="spacer-responsive"></span>

  <a mat-icon-button routerLink="home" class="logo">
    <img loading="lazy" src="assets/tomastrajan-gde.webp" alt="Tomas Trajan" />
  </a>
  <span class="project-name">{{
    (isResponsiveLayout | async) ? '' : 'TOMAS TRAJAN'
  }}</span>
  <iframe
    *ngIf="(columnCount | async) > 2"
    allowtransparency="true"
    frameborder="0"
    scrolling="no"
    src="//platform.twitter.com/widgets/follow_button.html?screen_name=tomastrajan&show_screen_name=false"
  ></iframe>

  <span class="spacer"></span>
  <ng-container *ngIf="(isResponsiveLayout | async) === false">
    <a mat-flat-button routerLink="home" routerLinkActive="active"> HOME </a>
    <a mat-flat-button routerLink="workshops" routerLinkActive="active">
      WORKSHOPS
    </a>
    <a mat-flat-button routerLink="consulting" routerLinkActive="active">
      CONSULTING
    </a>
    <a mat-flat-button routerLink="speaking" routerLinkActive="active">
      SPEAKING
    </a>
    <a mat-flat-button routerLink="open-source" routerLinkActive="active">
      {{ (columnCount | async) > 2 ? 'OPEN SOURCE' : 'OSS' }}
    </a>
    <a
      href="https://angularexperts.io/blog"
      target="_blank"
      rel="noopener"
      mat-flat-button
    >
      BLOG
    </a>
  </ng-container>
  <a
    mat-icon-button
    href="https://github.com/tomastrajan"
    target="_blank"
    rel="noopener"
  >
    <mat-icon
      svgIcon="github"
      aria-hidden="false"
      aria-label="GitHub"
    ></mat-icon>
  </a>
  <a
    mat-icon-button
    href="https://twitter.com/tomastrajan"
    target="_blank"
    rel="noopener"
  >
    <mat-icon
      svgIcon="twitter"
      aria-hidden="false"
      aria-label="Twitter"
    ></mat-icon>
  </a>
  <mat-icon
    class="theme-selector"
    [svgIcon]="isDarkMode ? 'lightbulb' : 'lightbulb-off'"
    (click)="toggleDarkMode()"
  ></mat-icon>
</mat-toolbar>
<mat-progress-bar
  *ngIf="isLoading | async"
  color="accent"
  mode="indeterminate"
></mat-progress-bar>
