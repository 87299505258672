<tt-toolbar
  (toggle)="onNavToggle()"
  [navOpened]="navOpened | async"
></tt-toolbar>
<mat-sidenav-container
  [hasBackdrop]="isSmallOrSmaller | async"
  (backdropClick)="onBackdropClick()"
>
  <mat-sidenav mode="over" [opened]="navOpened | async" disableClose>
    <tt-navigation (closeSideNav)="onBackdropClick()"></tt-navigation>
  </mat-sidenav>
  <mat-sidenav-content
    [style.margin-left]="(isSmallOrSmaller | async) === true ? 0 : '270px'"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<tt-footer></tt-footer>
