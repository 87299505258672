<div class="wrapper">
  <div class="intro">
    <div class="main-quote">
      <blockquote class="large">
        Empowering developer teams, taking initiative and helping You achieve
        Your goals with Angular
      </blockquote>
      <h1>TOMAS TRAJAN</h1>
      <p>Google Developer Expert for Angular and Web technologies</p>
    </div>
    <div class="avatar-wrapper">
      <img
        loading="lazy"
        class="avatar"
        src="assets/tomastrajan-gde.webp"
        alt="Tomas Trajan"
      />
    </div>
  </div>
</div>

<div class="wrapper-normal">
  <mat-grid-list
    class="highlights"
    [cols]="columnCount | async"
    rowHeight="640px"
    gutterSize="40"
  >
    <mat-grid-tile *ngFor="let item of highlights">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar>
            <img loading="lazy" [src]="item.logo" [alt]="item.logoAlt" />
          </div>
          <mat-card-title>{{ item.title }}</mat-card-title>
          <mat-card-subtitle>{{ item.subtitle }}</mat-card-subtitle>
        </mat-card-header>
        <img
          loading="lazy"
          mat-card-image
          [src]="item.photo"
          [alt]="item.photoAlt"
        />
        <mat-card-content>
          <p>{{ item.description }}</p>
        </mat-card-content>
        <mat-card-actions>
          <a
            *ngIf="item.link"
            color="accent"
            mat-flat-button
            [routerLink]="item.link"
            >{{ item.linkLabel }}</a
          >
          <a
            *ngIf="item.externalLink"
            color="accent"
            mat-flat-button
            rel="noopener"
            target="_blank"
            [href]="item.externalLink"
            >{{ item.linkLabel }}</a
          >
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="technologies">
    <img
      loading="lazy"
      src="../../../../assets/logo/angular.svg"
      alt="Angular"
      matTooltip="Angular"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/ngrx.svg"
      alt="NgRx"
      matTooltip="NgRx"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/rxjs.svg"
      alt="RxJs"
      matTooltip="RxJs"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/webcomponents.svg"
      alt="Web components"
      matTooltip="Web components"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/typescript.svg"
      alt="Typescript"
      matTooltip="Typescript"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/javascript.svg"
      alt="Javascript"
      matTooltip="Javascript"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/node.svg"
      alt="Node.js"
      matTooltip="Node.js"
      matTooltipPosition="above"
    />
    <img
      loading="lazy"
      src="../../../../assets/logo/gde.webp"
      alt="Google Developer Experts"
      matTooltip="Google Developer Expert for Angular"
      matTooltipPosition="above"
    />
  </div>

  <tt-get-in-touch></tt-get-in-touch>
</div>
