<span>© {{ year }}</span>
<span class="signature">
  <span>Made with</span>
  <mat-icon>favorite</mat-icon>
  <span> by </span>
  <a href="https://twitter.com/tomastrajan" target="_blank" rel="noopener">
    @tomastrajan</a
  >
</span>
<span>{{ version }}</span>
