<mat-nav-list>
  <ng-container *ngFor="let nav of navigation">
    <ng-container *ngIf="nav.url">
      <a
        mat-list-item
        [routerLink]="nav.url"
        routerLinkActive="active"
        #rla="routerLinkActive"
        (click)="close(!nav.children || nav.children.length < 2)"
      >
        {{ nav.label }}
        <mat-icon matListIcon>{{
          nav.children && rla.isActive ? 'expand_more' : 'chevron_right'
        }}</mat-icon>
      </a>

      <ng-container *ngIf="nav.children && rla.isActive">
        <a
          mat-list-item
          class="sub-nav"
          *ngFor="let child of nav.children"
          [routerLink]="child.url"
          routerLinkActive="active"
          (click)="close(true)"
        >
          {{ child.label }}
        </a>
      </ng-container>
    </ng-container>
    <a
      *ngIf="nav.externalUrl"
      mat-list-item
      [href]="nav.externalUrl"
      target="_blank"
      rel="noopener"
    >
      {{ nav.label }}
      <mat-icon matListIcon>chevron_right</mat-icon>
    </a>
  </ng-container>
</mat-nav-list>
